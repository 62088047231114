import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { WizardContent } from './WizardContent';
import { Logo } from './Logo';

export const Layout = ({ language }) => {
    const { i18n } = useTranslation(),
        [lang, setLang] = useState(language);

    const onLocaleChange = (e, lang) => {
        e.preventDefault();
        i18n.changeLanguage(lang);
    };

    useEffect(() => {
        if (['no', 'nb', 'nn', 'no-NO', 'nb-NO', 'nn-NO'].includes(i18n.language)) {
            setLang('no');
        } else if (i18n.language === 'en' || i18n.language.startsWith('en-')) {
            setLang('en');
        } else {
            setLang('sv');
        }
    }, [i18n.language]);

    if (language && language !== i18n.language) {
        onLocaleChange(language);
    }

    if (!lang) {
        return null;
    }

    return (
        <>
            <header>
                <section className='header__ident'>
                    <Logo />
                    <h1 className='header__title'>Søkewidget</h1>
                </section>
            </header>
            <main className='content'>
                <nav>
                    <button onClick={e => onLocaleChange(e, 'no')} aria-disabled={lang === 'no'}>Norsk</button>
                    <button onClick={e => onLocaleChange(e, 'sv')} aria-disabled={lang === 'sv'}>Svenska</button>
                    <button onClick={e => onLocaleChange(e, 'en')} aria-disabled={lang === 'en'}>English</button>
                </nav>
                <WizardContent language={lang} />
            </main>
            <footer>
                <Logo />
                <h3 className="footer__name">DigitaltMuseum</h3>
            </footer>
        </>
    );
};
