import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const OwnerSelect = ({ onChange, language }) => {
    const { t } = useTranslation(),
        [options, setOptions] = useState(),
        [selected, setSelected] = useState(''),
        [currCountry, setCurrCountry] = useState(language),
        [owners, setOwners] = useState();

    const handleChange = (oldId, newId) => {
        let s = selected.split(','),
            idx = s.indexOf(oldId);

        if (idx > -1) {
            s.splice(idx, 1);
        }
        
        if (newId) {
            s.push(newId);
        }

        setSelected(s.join(','));
        onChange && onChange(s.join(','));
    };

    const handleAdd = e => {
        const s = selected ? selected.split(',').concat(e.target.value) : [e.target.value];

        setSelected(s.join(','));
        onChange && onChange(s.join(','));
    };

    const setOwnersItem = (list, owner) => {
        let item = {
            'alpha': owner.name.substr(0, 1),
            'label': owner.name,
            'value': owner.identifier
        }
        
        list.push(item);
        
        if (owner.children) {
            list = setOwnersList(list, owner.children);
        }
        
        return list;
    };

    const setOwnersList = (list, data) => {
        data.map(owner => {
            list = setOwnersItem(list, owner);
            return true;
        });

        return list;
    };

    const setOwnersData = data => {
        const list = setOwnersList([], data);
        
        list.sort(function (a, b) {
            var textA = a.label.toUpperCase();
            var textB = b.label.toUpperCase();
            return (textA < textB) ? -1 : (textA > textB ? 1 : 0);
        });

        let alphalist = []
        let alphagroup = {}
        let alpha = '';

        list.map(owner => {
            alpha = owner.alpha;
            
            if (!alphagroup[alpha]) {
                alphalist.push(alpha);
                alphagroup[alpha] = [];
            }

            alphagroup[alpha].push(owner);
            return null;
        });

        return ({
            alphalist: alphalist,
            alphagroup: alphagroup,
            list: list
        });
    };

    const renderOption = (option, idx) => {
        const active = selected.indexOf(option.value) > -1;

        return <option key={idx} value={option.value} disabled={active}>{option.label}</option>;
    };

    const renderOptiongroup = (alpha, idx) => {
        return (
            <optgroup label={alpha} key={`${alpha}-${idx}`}>
                {options.alphagroup[alpha].map(renderOption)}
            </optgroup>
        )
    };

    const renderAdd = () => {
        return (
            <>
                <label htmlFor="addOwner">{t('wizard.options.source.add', 'Add source')}</label>
                <select id='addowner'
                    value=''
                    onChange={event => handleAdd(event)}>
                    <option value=''>{t('wizard.options.source.select', 'Select source')}</option>
                    {options.alphalist.map(renderOptiongroup)}
                </select>
            </>
        );
    };

    const renderSelectedOption = (id, idx) => {
        return (
            <select key={id} value={id} onChange={event => handleChange(id, event.target.value)}>
                <option value=''>{t('wizard.options.source.remove', 'Remove source')}</option>
                {options.alphalist.map(renderOptiongroup)}
            </select>
        );
    };

    const renderRemove = () => {
        const owners = selected && selected.split(',');

        if (!owners || !owners.length) {
            return null;
        }

        return (
            <>
                <label htmlFor='selectOwner'>
                    {`${owners.length} ${owners.length > 1 ? t('wizard.options.source.multiple', 'sources') : t('wizard.options.source.single', 'source')}`}
                </label>
                {owners.map(renderSelectedOption)}
            </>
        );
    };

    const renderCountrySelect = () => {
        return (
            <div className='radiogroup'>
                <input type='radio' id='no_source' value='no' onChange={() => setCurrCountry('no')} checked={currCountry === 'no'} />
                <label htmlFor='no_source'>{t('wizard.options.source.no', 'Norwegian sources')}</label>
                <input type='radio' id='sv_source' value='sv' onChange={() => setCurrCountry('sv')} checked={currCountry === 'sv'} />
                <label htmlFor='sv_source'>{t('wizard.options.source.sv', 'Swedish sources')}</label>
            </div>
        );
    };

    useEffect(() => {
        setCurrCountry(language);
    }, [language]);

    useEffect(() => {
        if (['no', 'sv'].includes(currCountry)) {
            setOptions(null);
            setOwners(null);

            fetch('/api/owners?country=' + (currCountry === 'sv' ? 'se' : currCountry))
                .then(response => response.json())
                .then((data) => {
                    setOwners(data);
                });
        }
    }, [setOwners, setOptions, currCountry]);

    if (currCountry === 'en') {
        return renderCountrySelect();
    }

    if (!options && owners) {
        setOptions(setOwnersData(owners));
    }

    if (!options) {
        return (
            <>
                {language === 'en' && renderCountrySelect()}
                <p>Loading...</p>
            </>);
    }

    return (
        <>
            {language === 'en' && renderCountrySelect()}
            {renderRemove()}
            {renderAdd()}
        </>
    );
};
