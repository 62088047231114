import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';

import { Layout } from './content/Layout';

export const App = () => {
    return (
        <Router>
            <Routes>
                <Route path='/en' render={() => <Layout language='en' />} />
                <Route path='/sv' render={() => <Layout language='sv' />} />
                <Route path='/no' render={() => <Layout language='no' />} />
                <Route path='/' element={<Layout />} />
            </Routes>
        </Router>
    );
};
