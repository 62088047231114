import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Code } from './Code';
import { LayoutSelect } from './LayoutSelect';
import { OwnerSelect } from './OwnerSelect';
import { Preview } from './Preview';
import { RowSelect } from './RowSelect';
import { ColumnSelect } from './ColumnSelect';
import { LanguageSelect } from './LanguageSelect';

export const WizardContent = ({ language }) => {
    const { t } = useTranslation(),
        [activeSection, setActiveSection] = useState(1),
        [setup, setSetup] = useState({
            query: '',
            layout: 'default',
            width: 0,
            rows: 1,
            cols: 4,
            owners: '',
            language: language
        });

    const onChange = (field, value) => {
        setSetup(s => ({ ...s, [field]: value }));
    };

    return (
        <article className='article'>
            <section>
                <h2>{t('wizard.head.title', 'Create your own search widget')}</h2>
                <p>{t('wizard.head.leadtext', 'Create your own widget that searches DigitaltMuseum.')}</p>
                <p>{t('wizard.head.bodytext', 'The widget can be inserted into any website, by simply pasting the code below. Comments? support@kulturit.no.')}</p>
            </section>
            <section>
                <p>
                    <strong>{t('wizard.preview.label', 'Preview')}: </strong>
                    {t('wizard.preview.description', 'Change layout to update.')}</p>
                <Preview width={setup.width} rows={setup.rows} cols={setup.cols} owners={setup.owners} query={setup.query} layout={setup.layout} language={setup.language} />
            </section>
            <section className='section--step' role='tab' aria-expanded={activeSection === 1}
                onClick={() => activeSection !== 1 && setActiveSection(1)}
            >
                <h3 data-step='1'>{t('wizard.layout.label', 'Select layout')}</h3>
                <div className='content'>
                    <p>{t('wizard.layout.description', 'Select layout options:')}</p>
                    <fieldset className='wizard__fieldset'>
                        <LanguageSelect value={setup.language} onChange={v => onChange('language', v)} />
                        <label className='form__label' htmlFor='query'>{t('wizard.options.query.label', 'Default search query')}</label>
                        <input type='text' id='query' placeholder={t('wizard.options.query.placeholder', 'Query or empty')} value={setup.query} onChange={e => onChange('query', e.target.value)} />
                        <div className='wizard__cols'>
                            <LayoutSelect onChange={v => onChange('layout', v)} />
                            <ColumnSelect value={setup.cols} onChange={v => onChange('cols', v)} />
                            <RowSelect defaultValue={setup.rows} onChange={v => onChange('rows', v)} />
                        </div>
                    </fieldset>
                </div>
            </section>
            <section className='section--step' role='tab' aria-expanded={activeSection === 2}
                onClick={() => activeSection !== 2 && setActiveSection(2)}
            >
                <h3 data-step='2'>{t('wizard.sources.label', 'Select sources')}</h3>
                <div className='content sources'>
                    <p>{t('wizard.sources.description', 'Filter on sources or leave blank for all sources:')}</p>
                    <fieldset className='wizard__fieldset'>
                        <OwnerSelect language={language} onChange={v => onChange('owners', v)} />
                    </fieldset>
                </div>
            </section>
            <section className='section--step' role='tab' aria-expanded={activeSection === 3}
                onClick={() => activeSection !== 3 && setActiveSection(3)}
            >
                <h3 data-step='3'>{t('wizard.code.label', 'Get embed code')}</h3>
                <div className='content code'>
                    <p>{t('wizard.code.description', 'Enter your details and submit to get the embed code. Your information will only be used for statistical purposes and in case we need to inform you of any changes.')}</p>
                    <Code width={0} rows={setup.rows} cols={setup.cols} owners={setup.owners} query={setup.query} layout={setup.layout} language={setup.language} />
                </div>
            </section>
        </article>
    );
};
