import React from 'react';
import { useTranslation } from 'react-i18next';

export const LanguageSelect = ({ value, onChange }) => {
    const { t } = useTranslation();

    const handleChange = v => {
        onChange && onChange(v);
    };

    return (
        <>
            <label>{t('wizard.options.language.label', 'Choose language for the widget')}</label>
            <div className='radiogroup language'>
                <input type='radio' id='no' value='no' onChange={() => handleChange('no')} checked={value === 'no'} />
                <label htmlFor='no'>{t('wizard.options.language.no', 'Norsk, bokmål')}</label>
                <input type='radio' id='nn-NO' value='nn-NO' onChange={() => handleChange('nn-NO')} checked={value === 'nn-NO'} />
                <label htmlFor='nn-NO'>{t('wizard.options.language.nn', 'Norsk, nynorsk ')}</label>
                <input type='radio' id='sv' value='sv' onChange={() => handleChange('sv')} checked={value === 'sv'} />
                <label htmlFor='sv'>{t('wizard.options.language.sv', 'Svenska')}</label>
            </div>
        </>
    );
};
