import React from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from './Select';

export const ColumnSelect = ({ value, onChange }) => {
    const { t } = useTranslation(),
        options = [
            {
                'label': `2 ${t('wizard.options.columns', 'columns')}`,
                'value': 2
            },
            {
                'label': `4 ${t('wizard.options.columns', 'columns')}`,
                'value': 4
            },
            {
                'label': `6 ${t('wizard.options.columns', 'columns')}`,
                'value': 6
            },
        ];

        const handleChange = v => {
            onChange && onChange(v);
        };

    return (
        <div>
            <label className='form__label' htmlFor='query'>{t('wizard.options.cols.label', 'Number of columns')}</label>
            <Select id='size' options={options} value={value} onChange={v => handleChange(parseInt(v))} />
        </div>
    );
};
