import React, { useEffect, useRef, useState } from 'react';

// eslint-disable-next-line
import DiMuSearchWidget from '@web-components/dimu-search-widget';

export const Preview = ({ width, rows, cols, owners, layout, query, language }) => {
    const preview = useRef(),
        [widgetAdded, setWidgetAdded] = useState(false);

    useEffect(() => {
        if (!widgetAdded) {
            // uri, width, rows, cols, owners, objects, style, search, lang, newTab, wrapperId
            preview.current.renderWidget(
                window.location.protocol + '//' + window.location.host,
                width, cols * rows, cols, owners || '', 'All',
                layout, query, language, false
            );

            setWidgetAdded(true);
        } else {
            preview.current.updateFieldValues(width, cols * rows, cols, owners, layout, query, language);
        }
    }, [widgetAdded, width, rows, cols, owners, layout, query, language]);

    return <kit-dimu-search-widget ref={preview}></kit-dimu-search-widget>
};
