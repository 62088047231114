import React from 'react';

export const Select = ({ id, options, value, onChange }) => {
    const handleChange = e => {
        onChange && onChange(e.target.value);
    };

    const renderOption = (option, idx) => {
        return <option key={idx} value={option.value}>{option.label}</option>;
    }

    return (
        <select id={id}
            value={value}
            onChange={event => handleChange(event)}>
            {options.map(renderOption)}
        </select>
    );
};
