import React from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from './Select';

export const RowSelect = ({ value, onChange }) => {
    const { t } = useTranslation(),
        options = [
            {
                'label': `1 ${t('wizard.options.row', 'row')}`,
                'value': 1
            },
            {
                'label': `2 ${t('wizard.options.rows', 'rows')}`,
                'value': 2
            },
            {
                'label': `3 ${t('wizard.options.rows', 'rows')}`,
                'value': 3
            },
            {
                'label': `4 ${t('wizard.options.rows', 'rows')}`,
                'value': 4
            }
        ];

    const handleChange = v => {
        onChange && onChange(v);
    };

    return (
        <div>
            <label className='form__label' htmlFor='query'>{t('wizard.options.rows_label', 'Number of rows')}</label>
            <Select id='rows' options={options} value={value} onChange={v => handleChange(parseInt(v))} />
        </div>
    );
};
