import React from 'react';

export const Logo = () => {
    return (
        <a href='https://digitaltmuseum.org' className='logo'>
            <svg version="1.1" viewBox="0 0 70 36">
                <path d="M70,0 L70,36 L0,36 L0,0 L70,0 Z M2,2 L2,34 L68,34 L68,2 L2,2 Z M34,2 L36,2 L36,34 L34,34 L34,2 Z" id="border"></path>
                <path d="M23.98,17.9 C23.98,15.94 23.78,13.84 22.32,12.34 C21.38,11.36 19.96,10.82 18.34,10.82 L12.82,10.82 L12.82,25 L18.34,25 C19.96,25 21.38,24.46 22.32,23.48 C23.78,21.98 23.98,19.86 23.98,17.9 L23.98,17.9 Z M21.36,17.9 C21.36,19.2 21.34,20.88 20.34,21.88 C19.86,22.36 19.16,22.68 18.34,22.68 L15.4,22.68 L15.4,13.14 L18.34,13.14 C19.16,13.14 19.86,13.46 20.34,13.94 C21.34,14.94 21.36,16.6 21.36,17.9 L21.36,17.9 Z" id="D"></path>
                <polygon id="M" points="59.12 25 59.12 10.82 55.82 10.82 52.08 20.92 48.36 10.82 44.88 10.82 44.88 25 47.38 25 47.38 14.76 51.04 24.8 52.94 24.8 56.62 14.88 56.62 25"></polygon>
            </svg>
        </a>
    );
};
