import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from './Select';

export const LayoutSelect = ({onChange}) => {
    const { t } = useTranslation(),
        options = [
            {
                'label': t('wizard.options.layout.default', 'Default'),
                'value': 'default'
            },
            {
                'label': t('wizard.options.layout.black', 'Dark'),
                'value': 'black'
            },
            {
                'label': t('wizard.options.layout.white', 'White'),
                'value': 'white'
            },
        ],
        [value, setValue] = useState(options[0]);

    const handleChange = value => {
        setValue(value);
        onChange && onChange(value);
    };

    return (
        <div>
            <label className='form__label' htmlFor='query'>{t('wizard.options.layout.label', 'Select layout')}</label>
            <Select id='layout' options={options} value={value} onChange={handleChange} />
        </div>
    );
};
