/**
 * class KIT DiMuSearchWidget
 */

import './css/widget.scss';

let timer = null;

class DiMuSearchWidget extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.log('log', 'KIT-DiMuSearchWidget @connectedCallback');

        this.ready();
    }

    /**
     * Run check until DOM elements
     * are ready before proceeding
     */
    ready() {
        let searchForm = document.getElementById('wsearch-form');

        if (searchForm) {
            const { thumbsize, rows, cols } = this.getFormElements();

            if (!thumbsize || isNaN(parseInt(thumbsize.value)) || parseInt(thumbsize.value) <= 0)  {
                this.updateFieldValues(this.offsetWidth, rows.value, cols.value);
            }

            this.addListeners();
            this.search();
        } else {
            this.log('log', 'KIT-DiMuSearchWidget @not ready!');
            setTimeout(() => {
                this.ready();
            }, 100);
        }
    }

    addListeners() {
        this.log('log', 'KIT-DiMuSearchWidget @addListeners');

        let searchForm = document.getElementById('wsearch-form');

        searchForm.onsubmit = (e) => this.searchSubmit(e);
    }

    addPagerListeners() {
        this.log('log', 'KIT-DiMuSearchWidget @addPagerListeners');

        let pager = document.getElementById('wsearch-pages'),
            next = pager.getElementsByClassName('next')[0],
            prev = pager.getElementsByClassName('prev')[0],
            page = document.getElementById('wpage').value || 1;

        page = parseInt(page, 10);

        if (next) {
            next.onclick = (e) => this.search(page + 1);
        }

        if (prev) {
            prev.onclick = (e) => this.search(page - 1);
        }
    }

    searchSubmit(e) {
        e.preventDefault();
        if (/simple$/.test(document.getElementById('wstyle').value)) {
            let host = document.getElementById('whostname').value,
                form = this.getFormElements(),
                owners = form.owners.value,
                ownerQ = '';

            if (owners) {
                owners = owners.split(',');
                ownerQ = `&aq=owner?:"${owners.join('","')}"`;
            }

            window.open(`https://${host}/search/?q=${form.q.value}${ownerQ}`, '_blank');
        } else {
            this.search();
        }
    }

    search(page) {
        let qObj = { page: page || 1 },
            searchForm = document.getElementById('wsearch-form');

        for (let el of searchForm.children) {
            switch (el.getAttribute('name')) {
                case 'q':
                    qObj.query = el.value;
                    break;
                case 'wuri':
                    qObj.uri = el.value;
                    if (qObj.uri.indexOf('localhost') === -1) {
                        qObj.uri = qObj.uri.replace(/^http:\/\//i, 'https://');
                    }
                    if (qObj.uri[qObj.uri.length - 1] === '/') {
                        qObj.uri = qObj.uri.slice(0, -1);
                    }
                    break;
                case 'wrows':
                    qObj.rows = el.value;
                    break;
                case 'wcols':
                    qObj.cols = el.value;
                    break;
                case 'wthumbsize':
                    qObj.thumbsize = el.value;
                    break;
                case 'wowners':
                    qObj.owners = el.value;
                    break;
                case 'whostname':
                    qObj.hostname = el.value;
                    break;
                case 'wlanguage':
                    qObj.language = el.value;
                    break;
                case 'wpage':
                    el.value = qObj.page;
                    break;
            }
        }

        this.log('log', `KIT-DiMuSearchWidget @search. Query: ${qObj.query}`);
        this.doSearch(qObj, 0);
    }

    doSearch(qObj) {
        let startRecord = ((qObj.page - 1) * qObj.rows);

        fetch(`${qObj.uri}/api/search?q=${qObj.query}&start=${startRecord}&rows=${qObj.rows}` +
                `&thumbsize=${qObj.thumbsize}&owners=${qObj.owners}&country=${qObj.language}`)
            .then(resp => {
                return resp.json();
            })
            .then(res => {
                this.processSearchResult(res, qObj);
            });
    }

    processSearchResult(res, qObj) {
        let searchresult = '',
            counter = 0,
            labelNrOfObjects,
            labelHere = 'her',
            labelNoObjectsFound,
            labelPreviousPage,
            labelNextPage = 'Neste side',
            resWrapper = document.getElementById('wsearch-result'),
            pager = document.getElementById('wsearch-pages'),
            pagerHtml = '',
            page = qObj.page,
            pages = Math.max(Math.ceil(res.count / qObj.rows), 1);

        this.log('log', `KIT-DiMuSearchWidget @processSearchResult. Count found:${res.count}`);

        // Define labels
        if (qObj.language === 'sv') {
            labelNrOfObjects = 'objekt totalt, se alla';
            labelHere = 'h&auml;r';
            labelNoObjectsFound = 'Inga objekt hittade';
            labelPreviousPage = 'F&ouml;reg&aring;ende sida';
            labelNextPage = 'N&auml;sta sida';
        } else if (qObj.language === 'nn-NO') {
            labelNrOfObjects = 'objekt totalt, sjå alle';
            labelNoObjectsFound = 'Ingen objekt funne';
            labelPreviousPage = 'Førre side';
        } else {
            labelNrOfObjects = 'objekter totalt, se alle';
            labelNoObjectsFound = 'Ingen objekter funnet';
            labelPreviousPage = 'Forrige side';
        }

        if (res.count > 0) {
            searchresult +=
                `<p>${res.count} ${labelNrOfObjects}
                    <a href="http://${qObj.hostname}${res.dmq_url}">${labelHere}
                </a></p>`;
        } else {
            searchresult += `<p>${labelNoObjectsFound}</p>`;
        }

        let searchrows = '';

        res.result.forEach(entry => {
            let html = '',
                img = entry.thumbnail ? `<img src="${entry.thumbnail}"
                    alt="${entry.title}"
                    title="${entry.title}" />` : `<span>${entry.title}</span>`;

            if (counter > (qObj.cols - 1)) {
                counter = 0;
            }

            html +=
                `<article class="witem">
                    <a href="http://${qObj.hostname}/${entry.dimu_code}"
                    target="_blank"
                    class="witem-grid">
                    ${img}
                </a></article>`;

            searchrows += html;
            ++counter;
        });

        if (searchrows) {
            searchresult += '<div class="wrows">' + searchrows + '</div>';
        }

        resWrapper.innerHTML = searchresult;

        // paging
        if (pages > 1) {
            if (page > 1) {
                pagerHtml += `<button class="prev">${labelPreviousPage}</button>`;
            }

            pagerHtml += `<span class="pages">${(page)}/${(pages)}</span>`;

            if (page < pages) {
                pagerHtml += `<button class="next">${labelNextPage}</button>`;
            }
        }

        pager.innerHTML = pagerHtml;
        this.addPagerListeners();
    }

    getFormElements() {
        return {
            rows: document.getElementById('wrows'),
            cols: document.getElementById('wcols'),
            thumbsize: document.getElementById('wthumbsize'),
            owners: document.getElementById('wowners'),
            style: document.getElementById('wstyle'),
            lang: document.getElementById('wlanguage'),
            q: document.getElementById('q')
        };
    }

    calcInputSize(width) {
        return parseInt(parseInt(width, 10) - 60, 10);
    }

    calcThumbSize(width, cols) {
        return parseInt((parseInt(width, 10)) / parseInt(cols, 10), 10) - 12;
    }

    updateFieldValues(width, rows, cols, owners, design, query, lang) {
        let wrapper = document.getElementById('wcontainer'),
            form = this.getFormElements();

        if ((query && form.q.value !== query) ||
            (rows && form.rows.value !== rows) ||
            (owners && form.owners.value !== owners)) {
            if (timer) { clearTimeout(timer); }
            timer = setTimeout(() => {
                this.search();
            }, 500);
        }

        rows && (form.rows.value = rows);
        cols && (form.cols.value = cols);
        width && (form.thumbsize.value = this.calcThumbSize(width, cols));
        owners && (form.owners.value = owners);
        design && (form.style.value = design);
        query && (form.q.value = query);
        lang && (form.lang.value = lang);

        if (width > 0) {
            wrapper.setAttribute('style', 'width: ' + width + 'px');
        }

        wrapper.setAttribute('data-layout', design);
        wrapper.setAttribute('data-rows', rows);
        wrapper.setAttribute('data-cols', cols);
    }

    renderWidget(uri, width, rows, cols, owners, objects, style, query, lang, newTab) {
        this.log('log', 'KIT-DiMuSearchWidget @renderWidget');
        const hostname = 'www.digitaltmuseum.org',
            wrapper = document.createElement('div'),
            tsize = this.calcThumbSize(width, cols),
            re = /simple$/,
            logo = `<svg version="1.1" viewBox="0 0 70 36">
                <path d="M70,0 L70,36 L0,36 L0,0 L70,0 Z M2,2 L2,34 L68,34 L68,2 L2,
                2 Z M34,2 L36,2 L36,34 L34,34 L34,2 Z" id="border"></path>
                <path d="M23.98,17.9 C23.98,15.94 23.78,13.84 22.32,12.34 C21.38,11.36 19.96,
                10.82 18.34,10.82 L12.82,10.82 L12.82,25 L18.34,25 C19.96,25 21.38,24.46 22.32,
                23.48 C23.78,21.98 23.98,19.86 23.98,17.9 L23.98,17.9 Z M21.36,17.9 C21.36,
                19.2 21.34,20.88 20.34,21.88 C19.86,22.36 19.16,22.68 18.34,22.68 L15.4,22.68 L15.4,
                13.14 L18.34,13.14 C19.16,13.14 19.86,13.46 20.34,13.94 C21.34,14.94 21.36,
                16.6 21.36,17.9 L21.36,17.9 Z" id="D"></path>
                <polygon id="M" points="59.12 25 59.12 10.82 55.82 10.82 52.08 20.92 48.36 10.82 44.88
                10.82 44.88 25 47.38 25 47.38 14.76 51.04 24.8 52.94 24.8 56.62 14.88 56.62 25"></polygon></svg>`;

        let language, labelSearchHelp, labelSearchPlaceholder,
            // inputsize = this.calcInputSize(width),
            html = '';

        // If param lang is undefined or invalid, use uri to find language
        if (typeof lang === 'undefined' || (!['no', 'nb', 'nn', 'no-NO', 'nb-NO', 'nn-NO', 'sv', 'sv-SE'].includes(lang))) {
            if (uri.indexOf('widgets.dimu.se') !== -1) {
                language = 'sv';
            } else {
                language = 'no';
            }
        } else {
            language = ['no', 'nb', 'nn', 'no-NO', 'nb-NO'].includes(lang) ? 'no' : lang;
        }

        if (typeof newTab === 'undefined' || (newTab !== true && newTab !== false)) {
            newTab = false;
        }

        // define text labels
        if (language === 'no') {
            labelSearchHelp = 'Angi s&oslash;keord og trykk "Enter"-knappen.';
            labelSearchPlaceholder = 'S&oslash;k i DigitaltMuseum';
        } else if (language === 'nn-NO') {
            labelSearchHelp = 'Angi s&oslash;kjeord og trykk "Enter"-knappen.';
            labelSearchPlaceholder = 'S&oslash;k i DigitaltMuseum';
        } else if (language === 'sv') {
            labelSearchHelp = 'Ange s&ouml;kord och klicka p&aring; "Enter"-knappen.';
            labelSearchPlaceholder = 'S&ouml;k i DigitaltMuseum';
        }

        wrapper.id = 'wcontainer';
        wrapper.setAttribute('data-layout', style);
        wrapper.setAttribute('data-rows', rows);
        wrapper.setAttribute('data-cols', cols);

        if (width > 0) {
            wrapper.setAttribute('style', 'width: ' + width + 'px');
        }

        html = `<header id="wheader">
                <a href="http://${hostname}/" id="wlogo"`;

        if (newTab === true) {
            html += 'target="_blank"';
        }

        html += `>${logo}</a>
            </header><section id="wcontent">`;

        if (re.test(style)) {
            html += `<form action="http://${hostname}/search" method="get" accept-charset="UTF-8">`;
        } else {
            html += '<form id="wsearch-form" name="wsearch-form" action="">';
            html += `<input type="hidden" id="wlanguage" name="wlanguage" value="${language}"/>`;
            html += `<input type="hidden" id="wnewtab" name="wnewtab" value="${newTab}"/>`;

            html += `<input type="hidden" id="whostname" name="whostname" value="${hostname}"/>`;
            html += `<input type="hidden" id="wuri" name="wuri" value="${uri}"/>`;
            html += `<input type="hidden" id="wrows" name="wrows" value="${rows}"/>`;

            html += `<input type="hidden" id="wcols" name="wcols" value="${cols}"/>`;
            html += '<input type="hidden" id="wpage" name="wpage" value="0"/>';
            html += `<input type="hidden" id="wthumbsize" name="wthumbsize" value="${tsize}"/>`;
        }

        html += `<input type="hidden" id="wowners" name="wowners" value="${owners}" />`;
        html += `<input type="hidden" name="wobjects" value="${objects}" />`;
        html += `<input type="hidden" id="wstyle" name="wstyle" value="${style}" />`;

        html += `<input type="text" placeholder="${labelSearchPlaceholder}"`;
        html += ` autocomplete="off" id="q" name="q" value="${query}" class="wsearch-input" required />`;
        html += '<input type="submit" value="&rarr;" class="wsearch-submit" />';
        html += '</form>';

        html += `<div id="wsearch-result"><p>${labelSearchHelp}</p></div>`;
        html += '<div id="wsearch-pages"></div>';
        html += '</section>';

        wrapper.innerHTML = html;
        this.appendChild(wrapper);
    }

    log(level, msg) {
        if (window.location.hostname.indexOf('localhost') !== -1) {
            if (level === 'info') {
                console.info(msg);
            } else if (level === 'debug') {
                console.debug(msg);
            } else if (level === 'log') {
                console.log(msg);
            } else if (level === 'warn') {
                console.warn(msg);
            } else if (level === 'error') {
                console.error(msg);
            }
        }
    }
}

customElements.define('kit-dimu-search-widget', DiMuSearchWidget);
export default DiMuSearchWidget;
