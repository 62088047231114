import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import i18n from './i18n';

import reportWebVitals from './reportWebVitals';
import unregister from './registerServiceWorker';
import './index.css';
import { App } from './App';

const Routes = () => (
    <Suspense fallback={'Henter DigitaltMuseum Søkewidget'}>
        <App />
    </Suspense>
);

i18n.init().then(
    () => createRoot(document.getElementById('root')).render(<Routes />, )
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
unregister();
