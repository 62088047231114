import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Embed } from './Embed';

export const Code = ({ width, rows, cols, owners, layout, query, language }) => {
    const { t } = useTranslation(),
        [errors, setErrors] = useState({ email: false, site: false }),
        [email, setEmail] = useState(),
        [site, setSite] = useState(),
        [registered, setRegistered] = useState();

    const onValidate = (name, value) => {
        if (name === 'email') {
            setErrors(err => ({ ...err, email: !value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) }));
            setEmail(value);
        } else if (name === 'site') {
            if (!/(?:https?):\/\//.test(value)) {
                value = 'http://' + value;
            }

            setErrors(err => ({ ...err, site: !/^(?:\w+:)?\/\/([^\s.]+\.\S{2}|localhost[:?\d]*)\S*$/.test(value) }));
            setSite(value);
        }
    };

    const onSubmit = () => {
        const payload = { email: email, site: site };
        let data = new FormData();

        for (let key in payload) {
            data.append(key, payload[key]);
        }

        fetch('/api/register-widget-user', {
            method: 'POST',
            body: data
        }).then(resp => {
            if (resp.ok) {
                setRegistered(true);
            }
            else {
                setRegistered('error');
            }
        });
    };

    return (
        <>
            <fieldset className='wizard__fieldset'>
                <label className='form__label' htmlFor='email'>{t('wizard.options.email.label', 'Email address')}</label>
                <input type='text' id='email' placeholder={t('wizard.options.email.placeholder', 'Your email address')} onChange={e => onValidate('email', e.target.value)} />
                {errors.email && <label className='help--error' htmlFor='email' role='alert'>{t('wizard.options.email.error', 'E-mail address is not valid')}</label>}
                <label className='form__label' htmlFor='site'>{t('wizard.options.site.label', 'Homepage')}</label>
                <input type='text' id='site' placeholder={t('wizard.options.site.placeholder', 'URL where wizard will be used')} onChange={e => onValidate('site', e.target.value)} />
                {errors.site && <label className='help--error' htmlFor='site' role='alert'>{t('wizard.options.site.error', 'Website address is not valid')}</label>}
            </fieldset>
            {!registered && <button disabled={!email || !site || errors.email || errors.site} onClick={onSubmit}>{t('wizard.code.button', 'Submit and show code')}</button>}
            {registered && (
                registered !== 'error'
                    ? (
                        <>
                            <p>{t('wizard.code.embed', 'Paste the snippet below into your website to show the DigitaltMuseum Search Widget:')}</p>
                            <Embed width={width} rows={rows} cols={cols} owners={owners} layout={layout} query={query} language={language} />
                        </>
                    ) : <label className='help--error' role='alert'>{t('wizard.options.register.error', 'We\'re sorry, there was a problem with the registration. Please contact support: support@kulturit.no.')}</label>
            )}
        </>
    )
};
